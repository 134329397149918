import React, {useState} from 'react';
import AdminPage from "../AdminPage/AdminPage";
import Login from "../../components/Admin/Login";

export default function LoginPage() {

    const [credentials, setCredentials] = useState(null);

    return (
        <React.Fragment>
            {
                credentials ? <AdminPage credentials={credentials}/> : <Login login={setCredentials}/>
            }
        </React.Fragment>
    )
}