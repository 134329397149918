
export const HIC_ROUTES = [
    {
        key: "route_home",
        name: "Home",
        route: "/",
        image: "homepage.jpg",
        content: "home.html",
        visible: true
    },
    {
        visible: true,
        key: "route_aboutus",
        name: "About Us",
        route: "/aboutus",
        image: "about.jpg",
        content: "about.html"
    },
    {
        visible: true,
        key: "route_services",
        name: "Services",
        route: "/services",
        image: "service.jpg",
        content: "services.html",
        children: [
            {
                visible: true,
                key: "route_order",
                name: "Order Inspection",
                route: "/orderinspection",
                image: "order-inspection.jpg",
                content: "order.html"
            }
        ]
    },
    {
        visible: true,
        key: "route_thermal",
        name: "Thermal Imaging",
        route: "/thermalimaging",
        image: "infra.jpg",
        content: "thermal.html"
    },
    {
        visible: true,
        key: "route_faqs",
        name: "FAQS",
        route: "/faqs",
        image: "faqs.jpg",
        content: "faqs.html"
    },
    {
        visible: true,
        key: "route_prepare",
        name: "Prepare for an inspection",
        route: "/prep",
        image: "prepare.jpg",
        content: "prepare.html"
    },
    {
        visible: true,
        key: "route_contact",
        name: "Contact us",
        route: "/contactus",
        image: "contactus.jpg",
        content: "contact.html"
    },
    {
        visible: false,
        key: "route_order",
        name: "Order Inspection",
        route: "/orderinspection",
        image: "order-inspection.jpg",
        content: "order.html"
    }
];