
let headers = {
    'Content-Type': 'application/json'
};

// headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));

export const doPost = (url, payload, handler, auth) => {

    if (auth) {
        headers["Authorization"] = "Basic " + auth;
    }

    fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)})
        .then(response=> response.json())
        .then(data => {
            handler(data);
        })
        .catch(error => {
            console.warn(error);
        });
}

export const doGet = (url, handler, auth) => {

    if (auth) {
        headers["Authorization"] = "Basic " + auth;
    }

    fetch(url, {
        method: 'GET',
        headers: headers,})
        .then(response=> response.json())
        .then(data => {
            handler(data);
        })
        .catch(error => {
            console.warn(error);
        });
}