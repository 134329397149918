import React from 'react';

import "./ImagePane.css";

export default function ImagePane(props) {

    const image = props.image ? props.image : "no-image.png";
    const imageWidth = props.imageWidth ? props.imageWidth: "100%";

    return (
        <React.Fragment>
            <div className={"imagepane-root"}>
                <img width={imageWidth} src={image} alt={"Page With Graphic Showing Services or Content"}/>
            </div>
        </React.Fragment>
    )

}