import React, {useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";

function App() {

  return (
      <React.Fragment>
          <BrowserRouter>
              <Routes>
                    <Route path={"/"} element={<HomePage/>}/>
                    <Route path={"/admin"} element={<LoginPage/>}/>
              </Routes>
          </BrowserRouter>
      </React.Fragment>
  );
}

export default App;
