import React, {useState} from 'react';
import {BarChart} from "@mui/x-charts";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import "./AdminPage.css";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider
} from "@mui/material";
import {doGet} from "../../helpers/NetUtil";

import VisibilityIcon from '@mui/icons-material/Visibility';
import {HIC_LIGHT_THEME} from "../../themes/LightTheme";
import ContactModal from "../../components/Modals/ContactModal";
import InspectionModal from "../../components/Modals/InspectionModal";

export default function AdminPage(props) {

    const credentials = props.credentials;

    // CONTACT DATA (FOR MODALS)
    const [currentContact, setCurrentContact] = useState(null);
    const [currentInspection, setCurrentInspection] = useState(null);

    const [errors, setErrors] = useState(false);
    const [options, setOptions] = useState({
        days: 10,
        maxRecords: 25
    });

    const [loadState, setLoadState] = useState(0);
    const [inspectionData, setInspectionData] = useState(null);
    const [contactData, setContactData] = useState(null);
    const [visitorData, setVisitorData] = useState(null);
    const [serverLoopRunning, setServerLoopRunning] = useState(false);

    const [serverData, setServerData] = useState({

        "uptime": 0,
        "memory": {
            "rss": 0,
            "heapTotal": 0,
            "heapUsed": 0,
            "external": 0,
            "arrayBuffers": 0
        },
        "osinfo": {
            "totalMem": 0,
            "usedMem": 0,
            "uptime": 0,
            "osVersion": "Unknown",
            "cpu": []
        },
        "disk": {
            "type": 0,
            "bsize": 0,
            "blocks": 0,
            "bfree": 0,
            "bavail": 0,
            "files": 0,
            "ffree": 0
        }
    });

    const STATE_INSPECTION_LOADED = 1;
    const STATE_CONTACTS_LOADED = 2;
    const STATE_VISITORS_LOADED = 3;

    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    const fetchContactData = () => {

        let now = (Date.now()/1000);
        now = now - (86400*options.days);

        doGet("/api/v1/contact/fetchsince?since="+now+"&maxRecords="+options.maxRecords, (data) => {

            if (data.error) {
                console.warn("There was an error fetching contact data...");
                setErrors(true);
            }
            else {
                setContactData(data);
                setLoadState(STATE_CONTACTS_LOADED);
            }
        },credentials)
    }

    const fetchInspectionData = () => {

        let now = (Date.now()/1000);
        now = now - (86400*options.days);

        doGet("/api/v1/inspection/list?since="+now+"&maxRecords="+options.maxRecords, (data) => {

            if (data.error) {
                console.warn("There was an error fetching inspection data...");
                setErrors(true);
            }
            else {
                setInspectionData(data);
                setLoadState(STATE_INSPECTION_LOADED);
            }
        },credentials);
    }

    const fetchVisitorData = () => {
        let now = (Date.now()/1000);
        now = now - (86400*30); // 30 days

        doGet("/api/v1/visitor/fetchvisitors?since="+now, (data) => {

            if (data.error) {
                console.warn("There was an error fetching inspection data...");
                setErrors(true);
            }
            else {
                setVisitorData(data);
                setLoadState(STATE_VISITORS_LOADED);
            }
        })
    }

    if (!inspectionData && !errors && loadState === 0){
        fetchInspectionData();
    }

    if (!contactData && !errors && loadState === STATE_INSPECTION_LOADED) {
        fetchContactData();
    }

    if (!visitorData && !errors && loadState === STATE_CONTACTS_LOADED){
        fetchVisitorData();
    }

    const marshallDate = (dateSeconds) => {
        let date = new Date(dateSeconds*1000);

        return date.toISOString().split("T")[0];
    }

    const calculateVisitorsBetweenRange = (start, end) => {

        let count = 0;

        for (let record of visitorData) {
            if (record.visit_date >= start && record.visit_date <= end) {
                count++;
            }
        }

        return count;
    }

    const build7DayReport = (date, dayOfWeek) => {

        let end = (date.getTime()/1000);
        let data = [];

        for (let i = 6; i >= 0; i-- ){

            let dayStart = end - (86400*i);
            let dayEnd = dayStart + 86400;
            data.push(calculateVisitorsBetweenRange(dayStart, dayEnd));
        }

        return data;
    }

    const buildDaysOfWeekFromToday = (dayOfWeek) => {

        let day = dayOfWeek;
        let total = 6;
        let week = ['','','','','','',''];

        week[total] = daysOfWeek[day];
        total--;

        while (total >= 0) {
            day--;

            if (day < 0) {
                day = 6;
            }

            week[total] = daysOfWeek[day];
            total--;
        }

        return week;
    }

    const buildBarChart = () => {

        const date = new Date(new Date().toLocaleDateString());
        const dayOfWeek = date.getDay();
        const visitors = build7DayReport(date, dayOfWeek);
        const days = buildDaysOfWeekFromToday(dayOfWeek);

        return <BarChart
                    xAxis={[{
                        scaleType: 'band',
                        data: days,
                    }]}
                    series={
                        [
                            { data: visitors }
                        ]
                    }
                    width={340}
                    height={300}
                />
    }

    const ensureNonZero = (val) => {
        if (val === 0) {
            val = 1;
        }

        if (isNaN(val)) {
            return 100;
        }

        return val;
    }

    const calculateCpuUsage = () => {
        const cpus = serverData.osinfo.cpu;

        let total = 0;
        let idle = 0;

        for (let cpu of cpus) {
            total += cpu.times.idle + cpu.times.sys + cpu.times.user + cpu.times.irq;
            idle += cpu.times.idle;

            break;
        }

        return 100 - parseInt((idle/total)*100);
    }

    const requestServerStatistics = () => {

        doGet("/api/v1/health", (data) => {

            setTimeout(requestServerStatistics, 5000);
            setServerData(data);
        }, credentials);
    }

    const startServerStatsTimer = () => {

        setTimeout(requestServerStatistics, 5000);
        setServerLoopRunning(true);
    }

    // STATISTICS
    const heapUsage = parseInt(((ensureNonZero(serverData.memory.heapUsed)
        / ensureNonZero(serverData.memory.heapTotal))*100));

    const serverMemUsage = 100 - parseInt(
        (ensureNonZero(serverData.osinfo.usedMem) /
            ensureNonZero(serverData.osinfo.totalMem))*100);

    const serverDiskUsage = parseInt(
        100 - ((ensureNonZero(serverData.disk.bfree) / ensureNonZero(serverData.disk.blocks))*100)
    );

    const serverCpuUsage = ensureNonZero(calculateCpuUsage());

    if (!serverLoopRunning) {
        startServerStatsTimer();
    }

    const openContactModal = (obj) => {
        console.log(obj);
        setCurrentContact(obj);
    }

    const openInspectionModal = (obj) => {
        console.log(obj);
        setCurrentInspection(obj);
    }

    return (
        !credentials ? <div>
                You are not authorized to view this page.
            </div> :
        <React.Fragment>

            { currentContact ? <ContactModal open={true} data={currentContact} onclose={setCurrentContact}/> : ""}
            { currentInspection ? <InspectionModal open={true} data={currentInspection} onclose={setCurrentInspection}/> : ""}
            <ThemeProvider theme={HIC_LIGHT_THEME}>
                <div className={"admin-page-root"}>

                    {/*contacts*/}
                    <div className={"admin-page-column-container"}>
                        <Paper elevation={10} style={{margin: "15px", width: "400px", maxHeight: "400px"}}>
                            <div style={{margin: "10px", flexGrow: 1.0}}>
                                <div className={"admin-page-title"}>CONTACTS</div>
                                <TableContainer style={{maxWidth: "400px", maxHeight: "325px"}}>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>id</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>name</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>date</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>view</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {contactData ?
                                            <TableBody>
                                                {contactData.rows.map((row) => {
                                                    return <TableRow key={"contact_" + row.id}>
                                                        <TableCell align={"center"}>
                                                            <span className={"admin-page-value"}>{row.id}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <span className={"admin-page-value"}>{row.name}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <span
                                                                className={"admin-page-value"}>{marshallDate(row.date)}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <IconButton onClick={() => openContactModal(row)}>
                                                                <VisibilityIcon color={"primary"}/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody> : ""}
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </div>

                    {/*inspections*/}
                    <div className={"admin-page-column-container"}>
                        <Paper elevation={10} style={{margin: "15px", width: "400px", maxHeight: "400px"}}>
                            <div style={{margin: "10px", flexGrow: 1.0}}>
                                <div className={"admin-page-title"}>INSPECTIONS</div>
                                <TableContainer style={{maxWidth: "400px", maxHeight: "325px"}}>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>id</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>name</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>date</span></TableCell>
                                                <TableCell align={"center"}><span
                                                    className={"admin-page-header"}>view</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {inspectionData ?
                                            <TableBody>
                                                {inspectionData.rows.map((row) => {
                                                    return <TableRow key={"contact_" + row.id}>
                                                        <TableCell align={"center"}>
                                                            <span className={"admin-page-value"}>{row.id}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <span className={"admin-page-value"}>{row.clientName}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <span
                                                                className={"admin-page-value"}>{marshallDate(row.date)}</span>
                                                        </TableCell>
                                                        <TableCell align={"center"}>
                                                            <IconButton onClick={() => openInspectionModal(row)}>
                                                                <VisibilityIcon color={"primary"}/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody> : ""}
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </div>

                    {/*visitor statistics*/}
                    <div className={"admin-page-column-container"}>
                        <Paper elevation={10} style={{margin: "15px", width: "400px", maxHeight: "400px"}}>
                            <div style={{margin: "10px", flexGrow: 1.0}}>
                                <div className={"admin-page-title"}>VISITORS (7D)</div>
                                {visitorData ? buildBarChart() : ""}
                            </div>
                        </Paper>
                    </div>

                    {/*server statistics*/}
                    <div className={"admin-page-column-container"}>
                        <Paper elevation={10} style={{margin: "15px", width: "400px", maxHeight: "400px"}}>
                            <div style={{margin: "10px", flexGrow: 1.0}}>
                                <div className={"admin-page-title"}>SERVER HEALTH</div>
                                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                    <div style={{
                                        marginRight: "20px",
                                        border: "solid 1px #4D7495",
                                        borderRadius: "15px"
                                    }}>
                                        <div style={{textAlign: "center", fontWeight: "bold", color: "#4D7495"}}>
                                            Heap Usage
                                        </div>
                                        <Gauge
                                            width={150}
                                            height={110}
                                            value={heapUsage}
                                            startAngle={-110}
                                            endAngle={110}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 18,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={
                                                ({value, valueMax}) => `${value} / ${valueMax}`

                                            }
                                        />
                                    </div>
                                    <div style={{
                                        marginLeft: "20px",
                                        border: "solid 1px #4D7495",
                                        borderRadius: "15px"
                                    }}>
                                        <div style={{textAlign: "center", fontWeight: "bold", color: "#4D7495"}}>
                                            Server Memory
                                        </div>
                                        <Gauge
                                            width={150}
                                            height={110}
                                            value={serverMemUsage}
                                            startAngle={-110}
                                            endAngle={110}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 20,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={
                                                ({value, valueMax}) => `${value} / ${valueMax}`

                                            }
                                        />
                                    </div>
                                </div>
                                <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "15px"}}>
                                    <div style={{
                                        marginRight: "20px",
                                        border: "solid 1px #4D7495",
                                        borderRadius: "15px"
                                    }}>
                                        <div style={{textAlign: "center", fontWeight: "bold", color: "#4D7495"}}>
                                            Disk Usage
                                        </div>
                                        <Gauge
                                            width={150}
                                            height={110}
                                            value={serverDiskUsage}
                                            startAngle={-110}
                                            endAngle={110}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 18,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={
                                                ({value, valueMax}) => `${value} / ${valueMax}`

                                            }
                                        />
                                    </div>
                                    <div style={{
                                        marginLeft: "20px",
                                        border: "solid 1px #4D7495",
                                        borderRadius: "15px"
                                    }}>
                                        <div style={{textAlign: "center", fontWeight: "bold", color: "#4D7495"}}>
                                            CPU Usage
                                        </div>
                                        <Gauge
                                            width={150}
                                            height={110}
                                            value={serverCpuUsage}
                                            startAngle={-110}
                                            endAngle={110}
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                    fontSize: 20,
                                                    transform: 'translate(0px, 0px)',
                                                },
                                            }}
                                            text={
                                                ({value, valueMax}) => `${value} / ${valueMax}`

                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}