
import React from 'react';

import "./Header.css";

// Resources
import HICLogo from "../../images/hic-logo.jpg";
import FaceBookLogo from "../../images/logos/facebook-icon.svg";
import LinkedInLogo from "../../images/logos/linkedin-icon.svg";
import YelpLogo from "../../images/logos/yelp_burst.svg";

export default function Header() {

    return(
        <React.Fragment>
            <div className={"header-root"}>
                <div className={"header-left"}>
                    <img className={"header-hic-logo"} src={HICLogo} alt={"Home Inspection Company Logo"}/>
                </div>
                <div className={"header-right"}>
                    <div className={"header-icon-buffer-margin header-hic-responsive"}>
                        <span className={"header-icon-margins"}>
                            <a href={"https://facebook.com/profile.php?id=100011061348332"} target={"_blank"} rel="noreferrer">
                                <img
                                    className={"header-hic-external-logo"}
                                    src={FaceBookLogo}
                                    alt={"Facebook Logo"}
                                    width={"36px"}
                                />
                            </a>
                        </span>
                        <span className={"header-icon-margins"}>
                            <a href={"https://www.linkedin.com/pub/paul-harriott/103/b34/2a7"} target={"_blank"}
                               rel="noreferrer">
                                <img className={"header-hic-external-logo"} src={LinkedInLogo} alt={"Facebook Logo"}
                                 width={"36px"}/>
                            </a>
                        </span>
                        <span className={"header-icon-margins"}>
                            <a href={"https://www.yelp.com/biz/the-home-inspection-company-san-diego"}
                               target={"_blank"}
                               rel="noreferrer">
                                <img className={"header-hic-external-logo"} src={YelpLogo} alt={"Facebook Logo"}
                                     width={"32px"}/>
                            </a>
                        </span>
                    </div>
                    <div className={"header-contact-schedule header-hic-responsive"}>
                        <div className={"schedule-appointment-title hic-font-bold hic-font-18"}>
                            Schedule an Appointment
                        </div>
                        <div className={"hic-font-30 hic-font-bold hic-font-color hic-header-font"}>
                            858-848-4476
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}