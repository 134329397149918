import React, {useState} from 'react';
import {AppBar, IconButton, ListItem, ListItemButton, ListItemText, Menu, MenuItem, ThemeProvider} from "@mui/material";

import {HIC_LIGHT_THEME} from "../../themes/LightTheme";
import {HIC_ROUTES} from "../../routes/Routes";

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import "./MenuBar.css";

export default function MenuBar(props) {

    const [anchor, setAnchor] = useState(null);
    const routeChange = props.routeChange;
    const routeMap = props.routeMap;

    const handleMenuClick = (obj) => {
        handleClose();
        const route = routeMap[obj.name];
        routeChange(route);
    }

    const handleClose = () => {
        setAnchor(null);
    }

    const handleOpen = (obj) => {
        setAnchor(obj.target);
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={HIC_LIGHT_THEME}>
                <AppBar position="static">
                    <div className={"menu-bar"}>
                        {HIC_ROUTES.map((route) => {
                            if (route.visible) {
                                return <React.Fragment key={"item_" + route.key}>
                                    <div className={"menu-item"}>
                                        <ListItem disablePadding>
                                            <ListItemButton sx={{textAlign: 'center'}}
                                                            onClick={() => handleMenuClick(route)}>
                                                <ListItemText><span
                                                    className={"hic-font-bold hic-font-14"}>{route.name}</span></ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    </div>
                                </React.Fragment>
                            } else {
                                return "";
                            }
                        })}
                    </div>
                    <div className={"menu-bar-responsive"}>
                        <div style={{width: "100%", border: "solid 1px"}}>
                            <span className={"hic-font-bold"}>NAVIGATION</span>
                            <IconButton aria-haspopup={true} onClick={handleOpen}>
                                <MenuOutlinedIcon color={"secondary"}/>
                            </IconButton>
                        </div>
                        <Menu
                            onClose={handleClose}
                            open={anchor !== null}
                            keepMounted={true}
                            anchorEl={anchor}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >

                        {HIC_ROUTES.map((route) => {
                            return <MenuItem key={route.key} onClick={() => handleMenuClick(route)}>{route.name}</MenuItem>
                        })}
                        </Menu>
                    </div>
                </AppBar>
            </ThemeProvider>
        </React.Fragment>
    )
}