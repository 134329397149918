
import React from 'react';
import {Button, ThemeProvider} from "@mui/material";

import {HIC_LIGHT_THEME} from "../../themes/LightTheme";

import "./ButtonBar.css";

export default function ButtonBar(props) {

    const routeChange = props.routeChange;
    const route = props.orderRoute;

    const showSampleReport = () => {
        window.open("sample-report.pdf", "_blank");
    }

    const showOrderInspection = () => {
        routeChange(route);
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={HIC_LIGHT_THEME}>
                <div className={"hic-margin-auto buttonbar-nonresponsive"} style={{marginTop: "30px", marginBottom: "30px", textAlign: "center"}}>
                    <span style={{marginRight: "20px"}}>
                        <Button variant={"contained"} color={"primary"} onClick={showOrderInspection}>
                            <span className={"hic-font-bold"}>Order An Inspection</span>
                        </Button>
                    </span>
                    <span style={{marginLeft: "20px"}}>
                        <Button variant={"contained"} color={"primary"} onClick={showSampleReport}>
                            <span className={"hic-font-bold"}>View Sample Report</span>
                        </Button>
                    </span>
                </div>
                <div className={"buttonbar-responsive"}>
                    <div style={{width: "100%", textAlign: "center"}}>
                        <Button variant={"contained"} color={"primary"} onClick={showOrderInspection}>
                            <span className={"hic-font-bold"}>Order An Inspection</span>
                        </Button>
                    </div>
                    <div style={{width: "100%", textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                        <Button variant={"contained"} color={"primary"} onClick={showSampleReport}>
                            <span className={"hic-font-bold"}>View Sample Report</span>
                        </Button>
                    </div>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}