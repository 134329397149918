import React, {useState} from 'react';

import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import "./ContactUs.css";
import {Button, Paper, TextField, ThemeProvider} from "@mui/material";
import {doPost} from "../../helpers/NetUtil";
import {HIC_LIGHT_THEME} from "../../themes/LightTheme";
import {isValidEmail, isValidPhoneNumber} from "../../helpers/Validators";

export default function ContactUs(props) {

    const readOnly = props.readOnly ? props.readOnly : false;
    const ROUTE_CHANGE_DELAY = 4000;
    const contactInfo = props.contact ? props.contact : {
        name: "",
        email: "",
        phone: "",
        message: ""
    };

    const [errors, setErrors] = useState({
        name: false,
        phone: false,
        email: false,
        overall: false
    });

    const [payload, setPayload] = useState(contactInfo);
    const [complete, setComplete] = useState(false);

    const updatePayload = (obj) => {
        const copy = { ...payload };
        copy[obj.target.name] = obj.target.value;

        setPayload(copy);
    }

    const handleSubmit = (res) => {
        if (!res.success) {
            const copy = { ...errors };
            copy.overall = true;

            setErrors(copy);
        }
        else {
            setComplete(true);
        }
    }

    const submitForm = () => {

        const copy = { ...errors };

        copy.name = payload.name.length <= 3;
        copy.email = !isValidEmail(payload.email);
        copy.phone = !isValidPhoneNumber(payload.phone);

        if (copy.name || copy.email || copy.phone) {
            setErrors(copy);
        }
        else {
            doPost("/api/v1/contact", payload, handleSubmit);
        }
    }

    const display1 = complete ? {display: "none"} : {display: "inherit"};
    const display2 = complete ? {display: "inherit"} : {display: "none"};

    if (complete) {
        setTimeout(() => window.location = "/", ROUTE_CHANGE_DELAY);
    }

    return (
        <React.Fragment>
            <Paper elevation={4}>
                <div className={"contact-us-root"}>
                    <div style={display1}>
                        <div style={ readOnly ? {display: "none"} : {display: "inherit"}} className={"contact-us-title hic-font-color hic-font-20"}>
                            Have questions?  You can contact us with the form below!
                        </div>
                        <div style={ readOnly ? {display: "inherit"} : {display: "none"}} className={"contact-us-title hic-font-color hic-font-20"}>
                            Contact Info
                        </div>
                        <div className={"contact-us-form"}>
                            <div>
                                <div className={"contact-us-field-title hic-font-color"}>
                                    Name
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        name={"name"}
                                        value={payload.name}
                                        onChange={updatePayload}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        error={errors.name}
                                        helperText={errors.name ?
                                            "The name provided must be at least 3 characters and less than 50." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"contact-us-field-container"}>
                                <div className={"contact-us-field-title hic-font-color"}>
                                    Email
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        name={"email"}
                                        value={payload.email}
                                        onChange={updatePayload}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        error={errors.email}
                                        helperText={errors.email ?
                                            "The email address provided is not recognized as valid." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"contact-us-field-container"}>
                                <div className={"contact-us-field-title hic-font-color"}>
                                    Phone
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        name={"phone"}
                                        value={payload.phone}
                                        onChange={updatePayload}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        error={errors.phone}
                                        helperText={errors.phone ?
                                            "The phone number provided must contain at least 10 numbers." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"contact-us-field-container"}>
                                <div className={"contact-us-field-title hic-font-color"}>
                                    Message
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        rows={4}
                                        multiline
                                        name={"message"}
                                        value={payload.message}
                                        onChange={updatePayload}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        error={errors.overall}
                                        helperText={errors.overall ?
                                            "There was a technical problem submitting the entire form." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"hic-margin-top-10"} style={readOnly ? {textAlign: "center", display: "none"} : {textAlign: "center"}}>
                                <Button onClick={submitForm} variant={"contained"}>
                                    <span className={"hic-font-bold"}>Send</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={display2} className={"contact-us-form-complete"}>
                        <div>
                            <ThemeProvider theme={HIC_LIGHT_THEME}>
                                <MarkEmailReadIcon color="primary" style={{width: "200px", height: "200px"}}/>
                            </ThemeProvider>
                        </div>
                        <div className={"hic-font-color hic-font-bold"}>
                            Thank you for Contacting The Home Inspection Company!  We will generally respond with 24 hours.
                        </div>
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    )
}