import React from 'react';

import "./Footer.css";

import HIC_LOGO from "../../images/hic-logo-transparent.png";
import HIC_CRIEA from "../../images/CRIEA.png";

export default function Footer(props) {

    const routes = props.routes;
    const routeChange = props.routeChange;

    const handleRouteChange = (route) => {
        routeChange(route);
    }

    return (
        <React.Fragment>
            <div className={"footer-root"}>
                <div className={"footer-creia"}>
                    <img src={HIC_CRIEA} alt={"CRIEA logo"} width={60}/>
                </div>
                <div style={{marginTop: "30px"}} className={"footer-menuitems hic-margin-left-10"}>
                    {
                        routes.map(route => {
                            if (route.visible) {
                                return <span key={route.key} className={"footer-menuitem hic-font-12"}
                                             onClick={() => handleRouteChange(route)}>{route.name}</span>
                            }
                            else {
                                return "";
                            }
                        })
                    }
                </div>
                <div className={"footer-logo hic-margin-top-10"}>
                    <img src={HIC_LOGO} alt={"Home Inspection Company Logo"}/>
                </div>
            </div>
        </React.Fragment>
    )
}