
import React, {useState} from 'react';

import "./OrderInspection.css";
import {Button, MenuItem, Paper, TextField, ThemeProvider} from "@mui/material";
import {isValidEmail, isValidPhoneNumber} from "../../helpers/Validators";
import {doPost} from "../../helpers/NetUtil";
import {HIC_LIGHT_THEME} from "../../themes/LightTheme";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

export default function OrderInspection(props) {

    const data = props && props.inspection ? props.inspection : {
        clientName: "",
        clientPhone: "",
        clientEmail: "",
        agentName: "",
        agentOffice: "",
        agentPhone: "",
        agentEmail: "",
        propertyAddress: "",
        propertyYearBuilt: "",
        propertySquareFootage: "",
        propertyOccupied: 0,
        comments: ""
    }

    console.log("Current Data");
    console.log(data);

    const readOnly = props.readOnly ? props.readOnly : false;
    const [complete, setComplete] = useState(false);

    const [errors, setErrors] = useState({
        clientName: false,
        clientPhone: false,
        clientEmail: false,
        agentName: false,
        agentOffice: false,
        agentPhone: false,
        agentEmail: false,
        propertyAddress: false,
        propertyYearBuilt: false,
        propertySquareFootage: false,
        propertyOccupied: false,
        comments: false,
        overall: false
    });

    const [payload, setPayload] = useState(data);

    // 0 text
    // 1 phone
    // 2 email
    // 3 number
    const validations = [
            {field: "clientPhone", validator: 1},
            {field: "clientEmail", validator: 2},
            {field: "clientName", validator: 0},
            {field: "propertyAddress", validator: 0},
            {field: "propertyYearBuilt", validator: 3},
            {field: "propertySquareFootage", validator: 3},
        ];

    const updatePayload = (obj) => {
        const copy = { ...payload };
        copy[obj.target.name] = obj.target.value;

        setPayload(copy);
    }

    const validateRequiredEntries = () => {

        let errorCopy = {...errors};
        let hasErrors = false;

        for (let i = 0; i < validations.length; i++ ){
            const obj = validations[i];

            switch (parseInt(obj.validator)) {
                case 0:
                    errorCopy[obj.field] = payload[obj.field].length <= 3;
                    break;
                case 1:
                    errorCopy[obj.field] = !isValidPhoneNumber(payload[obj.field]);
                    break;
                case 2:
                    errorCopy[obj.field] = !isValidEmail(payload[obj.field]);
                    break;
                case 3:
                    errorCopy[obj.field] = !(payload[obj.field].length > 0 && !isNaN(parseInt(payload[obj.field])));
                    break;
                default:
                    errorCopy["overall"] = true;
                    break;
            }

            hasErrors = (!hasErrors) ? errorCopy[obj.field] : hasErrors;
        }

        if (hasErrors) {
            setErrors(errorCopy);
        }

        return hasErrors;
    }

    const handleInspectionCreation = (rsp) => {

        if (!rsp.err.error) {
            setComplete(true);
        }
    }

    const handleSubmit = () => {
        if (!validateRequiredEntries()) {
            doPost("/api/v1/inspection/requestinspection", payload, handleInspectionCreation);
        }
    }

    const display1 = complete ? {display: "none"} : readOnly ? {display: "flow", width: "inherit", margin: "initial"} : {};
    const display2 = complete ? {marginTop: "20px", marginBottom: "40px"} : {display: "none"};
    const display3 = readOnly ? {
        display: "inline",
        margin: "auto",
        maxWidth: "550px",
        minWidth: "550px",
        textAlign: "center"
    } : {}
    const display4 = readOnly ? {
        minWidth: "unset",
        maxWidth: "unset"
    } : {}

    const display5 = readOnly ? {
        display: "none"
    } : {textAlign: "center", margin: "auto"}

    const display6 = readOnly ? {
        display: "none"
    } : {}

    if (complete) {
        setTimeout(() => window.location = "/", 5000);
    }

    return (
        <React.Fragment>
            <div style={display1} className={"order-inspection-root"}>
                <div className={"hic-font-30 hic-font-bold hic-font-color order-inspection-small"}
                     style={display5}>
                    Order a Property Inspection
                </div>
                <div className={"order-inspection-container"} style={display3}>
                    <div className={"order-inspection-paper"}>
                        <Paper elevation={6}>
                            <div style={{textAlign: "center", padding: "20px", margin: "20px"}}>
                                <div className={"hic-font-20 hic-font-bold hic-font-color order-inspection-responsive"}
                                     style={{marginBottom: "20px", textDecoration: "underline"}}>Client Information
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color"}
                                         style={{textAlign: "left"}}>
                                        Client's Name
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"clientName"}
                                            value={payload.clientName}
                                            onChange={updatePayload}
                                            error={errors.clientName}
                                            helperText=
                                                {errors.clientName ? "The client's name must be provided." : ""}
                                        />
                                    </div>
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color  order-inspection-responsive"}
                                         style={{textAlign: "left"}}>
                                        Client's Phone
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"clientPhone"}
                                            value={payload.clientPhone}
                                            onChange={updatePayload}
                                            error={errors.clientPhone}
                                            helperText=
                                                {errors.clientPhone ? "The client's phone number must be provided." : ""}
                                        />
                                    </div>
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color  order-inspection-responsive"}
                                         style={{textAlign: "left"}}>
                                        Client's Email
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"clientEmail"}
                                            value={payload.clientEmail}
                                            onChange={updatePayload}
                                            error={errors.clientEmail}
                                            helperText=
                                                {errors.clientEmail ? "The email address provided does not appear to be valid" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <div className={"order-inspection-paper"}>
                        <Paper elevation={6}>
                            <div style={{textAlign: "center", padding: "20px", margin: "20px"}}>
                                <div className={"hic-font-20 hic-font-bold hic-font-color  order-inspection-responsive"}
                                     style={{marginBottom: "20px", textDecoration: "underline"}}>Agent Information (if applicable)
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color"}
                                         style={{textAlign: "left"}}>
                                        Agent's Name
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"agentName"}
                                            value={payload.agentName}
                                            onChange={updatePayload}
                                        />
                                    </div>
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                         style={{textAlign: "left"}}>
                                        Agent's Real Estate Office
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"agentOffice"}
                                            value={payload.agentOffice}
                                            onChange={updatePayload}
                                        />
                                    </div>
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                         style={{textAlign: "left"}}>
                                        Agent's Phone
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"agentPhone"}
                                            value={payload.agentPhone}
                                            onChange={updatePayload}
                                        />
                                    </div>
                                </div>
                                <div className={"hic-margin-top-10"}>
                                    <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                         style={{textAlign: "left"}}>
                                        Agent's Email
                                    </div>
                                    <div>
                                        <TextField

                                            slotProps={{
                                                input: {
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            size={"small"}
                                            variant={"outlined"}
                                            className={"contact-us-field"}
                                            name={"agentEmail"}
                                            value={payload.agentEmail}
                                            onChange={updatePayload}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </div>
                </div>
                <div className={"order-inspection-maxwidth"} style={display4}>
                    <Paper elevation={6}>
                        <div style={{textAlign: "center", padding: "20px", margin: "20px"}}>
                            <div className={"hic-font-20 hic-font-bold hic-font-color order-inspection-responsive"}
                                 style={{marginBottom: "20px", textDecoration: "underline"}}>Property Information
                            </div>
                            <div className={"hic-margin-top-10"}>
                                <div className={"contact-us-field-title hic-font-color"}
                                     style={{textAlign: "left"}}>
                                    Address
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        name={"propertyAddress"}
                                        value={payload.propertyAddress}
                                        onChange={updatePayload}
                                        error={errors.propertyAddress}
                                        helperText=
                                            {errors.propertyAddress ? "The property address must be provided." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"hic-margin-top-10"}>
                                <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                     style={{textAlign: "left"}}>
                                    Year Built
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        name={"propertyYearBuilt"}
                                        value={payload.propertyYearBuilt}
                                        onChange={updatePayload}
                                        error={errors.propertyYearBuilt}
                                        helperText=
                                            {errors.propertyYearBuilt ? "The property year must be provided and be a valid number." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"hic-margin-top-10"}>
                                <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                     style={{textAlign: "left"}}>
                                    Square Footage
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        name={"propertySquareFootage"}
                                        value={payload.propertySquareFootage}
                                        onChange={updatePayload}
                                        error={errors.propertySquareFootage}
                                        helperText=
                                            {errors.propertySquareFootage ? "The property sq footage must be provided and be a valid number." : ""}
                                    />
                                </div>
                            </div>
                            <div className={"hic-margin-top-10"}>
                                <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                     style={{textAlign: "left"}}>
                                    Is Property Occupied
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        select
                                        size={"small"}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        style={{textAlign: "left"}}
                                        name={"propertyOccupied"}
                                        value={payload.propertyOccupied}
                                        onChange={updatePayload}
                                    >
                                        <MenuItem value={1}>Occupied</MenuItem>
                                        <MenuItem value={0}>Not Occupied</MenuItem>
                                    </TextField>
                                </div>
                            </div>
                            <div className={"hic-margin-top-10"}>
                                <div className={"contact-us-field-title hic-font-color order-inspection-responsive"}
                                     style={{textAlign: "left"}}>
                                    Comments
                                </div>
                                <div>
                                    <TextField

                                        slotProps={{
                                            input: {
                                                readOnly: readOnly,
                                            },
                                        }}
                                        multiline
                                        rows={4}
                                        variant={"outlined"}
                                        className={"contact-us-field"}
                                        name={"comments"}
                                        value={payload.comments}
                                        onChange={updatePayload}
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>

                <div style={{margin: "auto", paddingBottom: "20px", textAlign: "center"}}>
                    <Button onClick={handleSubmit} variant={"contained"} style={display6}>
                        <span className={"hic-font-bold"}>Submit</span>
                    </Button>
                </div>
            </div>
            <div style={display2}>
                <div style={display2} className={"contact-us-form-complete"}>
                    <div>
                        <ThemeProvider theme={HIC_LIGHT_THEME}>
                            <MarkEmailReadIcon color="primary" style={{width: "200px", height: "200px"}}/>
                        </ThemeProvider>
                    </div>
                    <div className={"hic-font-color hic-font-bold"}>
                        Thank you for requesting an inspection from The Home Inspection Company! We will generally respond with 24 hours with next steps!
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}