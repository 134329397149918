
export function isLeapYear(year) {
    return new Date(year, 1, 29).getMonth() === 1;
}

export function isValidEmail(emailAddress) {

    if (emailAddress != null) {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return emailAddress.match(regex) != null;
    }

    return false;
}

// at some point I need to actually care about this function
export function isValidZipCode(zipCode) {

    if (zipCode != null) {
        let digits = zipCode.toString().split('');

        if (digits.length === 5) {
            let num = parseInt(zipCode);

            if (num > 0 && num < 99999) {
                return true;
            }
        }
    }

    return false;
}

export function isValidBirthdate(dateString) {

    if (dateString != null) {
        let values = dateString.split("/");
        let now = new Date();

        if (values.length === 3) {
            let year = parseInt(values[2]);
            let mon = parseInt(values[0]);
            let day = parseInt(values[1]);


            if (!isNaN(year) && !isNaN(mon) && !isNaN(day)) {
                if (year > 1900 && year <= now.getFullYear() &&
                    mon > 0 && mon < 13 &&
                    day > 0 && day < 32) {

                    let actualDate = new Date(year, mon - 1, day);

                    return actualDate.getMonth() === mon - 1;
                }
            }
        }
    }

    return false;
}

export function isValidText(text) {
    return text != null && text.length > 0;
}

export function isValidPassword(pass) {

    if (pass != null) {
        const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,30}$/;

        return pass.match(regex) != null;
    }

    return false;
}

export function isValidPhoneNumber(phone) {
    const digits = phone.split('');
    const numbers = [];

    for (let i = 0; i < digits.length; i++ ) {
        if (!isNaN(digits[i])) {
            numbers.push(digits[i]);
        }
    }

    return numbers.length > 9 && numbers.length < 12;
}