import {createTheme} from "@mui/material";


export const HIC_LIGHT_THEME = createTheme({
    palette: {
        primary: {
            main: "#4D7495"
        },
        secondary: {
            main: "#FFFFFF"
        },
    },
});