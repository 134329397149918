
import React, {useState} from 'react';
import {Box, Modal} from "@mui/material";
import ContactUs from "../ContactUs/ContactUs";

export default function ContactModal(props) {

    const [open, setOpen] = useState(props.open);
    const data = props.data ? props.data : null;
    const setCurrentContact = props.onclose;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setCurrentContact(null);
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <ContactUs contact={data} readOnly={true}/>
                </Box>
            </Modal>

        </React.Fragment>
    );

}