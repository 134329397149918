import React, {useState} from 'react';
import {Box, Button, Modal, TextField} from "@mui/material";

import Logo from "../../images/hic-logo-transparent.png";
import {doPost} from "../../helpers/NetUtil";

export default function Login(props) {

    const setCredentials = props.login;

    const [open, setOpen] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState({
        user: "",
        pass: ""
    })
    const defaultErrorState= {
        user: false,
        pass: false,
        invalid: false
    };

    const [errors, setErrors] = useState(defaultErrorState);

    const handleClose = (obj) => {{

        window.location = "/";
    }}

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const checkForErrors = () => {

        let error = {...errors};
        if (user.user.length === 0){
            error.user = true;
        }
        if (user.pass.length === 0) {
            error.pass = true;
        }

        setErrors(error);

        return error.user || error.pass;
    }

    const handleSubmit = () => {

        if (!checkForErrors()) {

            doPost("/api/v1/auth", user, (rsp) => {
                if (rsp.auth) {
                    setCredentials(rsp.credentials);
                }
                else {
                    let copyErrors = {...errors};
                    let copyUser = {...user};
                    copyErrors.invalid = true;

                    setErrors(copyErrors);
                    setUser(copyUser);
                }
            });
        }
    }

    const handleUpdate  = (obj) => {

        let copy = { ...user };
        copy[obj.target.name] = obj.target.value;

        setUser(copy);
        setErrors(defaultErrorState);
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{textAlign: "center"}}>
                        <img src={Logo}/>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <div style={{fontSize: "12px", fontWeight: "bold", color: "#4D7495"}}>
                            USERNAME
                        </div>
                        <div>
                            <TextField
                                value={user.user}
                                onChange={handleUpdate}
                                name={"user"}
                                style={{width: "100%"}}
                                variant={"outlined"}
                                size={"small"}
                                error={errors.user}
                                helperText={errors.user ? "You must provide a user name to continue." : ""}
                            />
                        </div>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <div style={{fontSize: "12px", fontWeight: "bold", color: "#4D7495"}}>
                            PASSWORD
                        </div>
                        <div>
                            <form>
                            <TextField
                                autoComplete={"on"}
                                value={user.pass}
                                type={"password"} name={"pass"}
                                onChange={handleUpdate}
                                style={{width: "100%"}}
                                variant={"outlined"}
                                size={"small"}
                                error={errors.pass}
                                helperText={errors.pass ? "You must provide a password to continue.":""}
                            />
                            </form>
                        </div>
                    </div>
                    <div style={{marginTop: "20px", textAlign:"center"}}>
                        <Button onClick={handleSubmit} variant={"outlined"} style={{width: "150px"}}>Login</Button>
                    </div>
                    <div style={{display: errors.invalid ? "inherit":"none", textAlign:"center", marginTop: "15px", color: "red"}}>
                        credentials invalid!  try again.
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    )
}