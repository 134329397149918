
import React, {useState} from 'react';

import "./HomePage.css";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menu/MenuBar";

import {HIC_ROUTES} from "../../routes/Routes";
import ImagePane from "../../components/ImagePane/ImagePane";
import {AppBar, CircularProgress, ThemeProvider} from "@mui/material";
import {HIC_LIGHT_THEME} from "../../themes/LightTheme";

import parse from 'html-react-parser'
import ButtonBar from "../../components/ButtonBar/ButtonBar";
import Footer from "../../components/Footer/Footer";
import ContactUs from "../../components/ContactUs/ContactUs";
import OrderInspection from "../../components/OrderInspection/OrderInspection";

export default function HomePage() {

    // Helper Functions
    const createRouteMap = () => {
        const map = {};

        HIC_ROUTES.map((route) => {
            return map[route.route] = route;
        });

        return map;
    }

    const createRouteMapByName = () => {
        const map = {};

        HIC_ROUTES.map((route) => {
            return map[route.name] = route;
        });

        return map;
    }

    const routeMap = createRouteMap();
    const routeMapByName = createRouteMapByName();
    const [route, setRoute] = useState(routeMap["/"]);
    const [content,setContent] = useState(null);

    const prepareContent = (content) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(content, "text/html")

        setContent(doc.body.children[0].outerHTML);
    }

    // The pages are dynamically loaded and will eventually be editable by the user
    fetch("page-content/"+route.content)
        .then(response => response.text())
        .then(body => prepareContent(body))
        .catch(error => console.log(error));

    window.scrollTo(0,0);

    return (

        <React.Fragment>
            <ThemeProvider theme={HIC_LIGHT_THEME}>
                <div className={"homepage-root"}>
                    <Header/>
                    <MenuBar routeChange={setRoute} routeMap={routeMapByName}/>
                    <ImagePane image={"page-images/"+route.image}/>
                    <AppBar position="static">
                        <div className={"homepage-appointment-bar"}>
                            <div className={"menu-item menu-hic-responsive"}>
                                <div>Schedule an appointment today</div>
                                <div className={"homepage-hyphen"}>-</div>
                                <div>858-848-4476</div>
                            </div>
                        </div>
                    </AppBar>
                    <div>
                            {!content ? <div style={{width: "100%", marginTop: "30px", textAlign: "center"}}>
                            <CircularProgress color={"primary"}/>
                        </div> : parse(content)}
                    </div>
                    {route.route !== "/orderinspection" ? <ButtonBar routeChange={setRoute} orderRoute={routeMapByName["Order Inspection"]}/> : ""}
                    {route.route === "/contactus" ? <ContactUs/> : ""}
                    {route.route === "/orderinspection" ? <OrderInspection/> : ""}
                    <Footer routes={HIC_ROUTES} routeChange={setRoute}/>
                </div>
            </ThemeProvider>
        </React.Fragment>
    )
}